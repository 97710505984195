import { useMutation, useQuery } from '@apollo/client';
import {
  GetCraftsWithUsersDocument,
  CreateFlightDocument,
  IntervalType,
  FlightCreateWithIntervalsInput,
  GetFlightPurposesDocument,
  GetFlightsDocument,
  LogType,
  GetCraftDocument,
  IntervalSubtype,
  GetOrganizationFlightBillingDocument,
  GetReportInfoDocument,
  GetTransactionsDocument,
} from 'graphql/generated';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useSession } from 'contexts';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Toast, { useToast } from 'components/Toast/Toast';
import { FormLabel, FullFormikCheckBox, FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Card from 'components/Card/Card';
import DatePicker from 'components/DatePicker/DatePicker';
import Button from 'components/Button/Button';
import RouteItem, { Route } from '../Components/RouteItem';
import IntervalItem from '../Components/IntervalItem';
import FilePicker from 'components/FilePicker/FilePicker';
import { formatToFixedNumber, formatUSD } from 'utils/formatter';
import { webFormat } from 'utils/statuses';
import PassengerItem, { Passenger } from '../Components/PassengerItem';
import { OrgSubtypes } from 'utils/orgTypes';


export const FlightSchema = Yup.object().shape({
  flightDate: Yup.date().required("Required"),
  fuelAdded: Yup.number().typeError('Value must be a valid number').nullable(),
  oilAdded: Yup.number().typeError('Value must be a valid number').nullable(),
  intervals: Yup.array().of(Yup.object().shape({
    startValue: Yup.number().required("Required").typeError('Value must be a valid number'),
    endValue: Yup.number().required("Required").when('startValue', (inValue, schema) =>
      schema.moreThan(inValue, 'Value cannot be less than start value')
    ).typeError('Value must be a valid number'),
  })).required("Required"),
  consumables: Yup.array().of(Yup.object().shape({
    startValue: Yup.number().nullable().typeError('Value must be a valid number').optional(),
    endValue: Yup.number().nullable().typeError('Value must be a valid number').optional(),
  })),
});


const New = function ({ closeFlyout, icaos }: { closeFlyout: () => void ; icaos?: Route[] }) {
  const { showToast, toastProps } = useToast();
  const { organizationId, craftId, organizationSubtype, user } = useSession();
  const [createFlight] = useMutation(CreateFlightDocument, {
    refetchQueries: [
      GetFlightsDocument,
      GetTransactionsDocument,
      { query: GetFlightPurposesDocument, variables: { where: { organizationId: { equals: organizationId } } } },
      { query: GetCraftDocument, variables: { craftId: craftId ?? '' } },
      { query: GetReportInfoDocument, variables: { id: organizationId } },
      { query: GetCraftsWithUsersDocument, variables: { organizationId } },
    ],
  });
  const { data: { crafts } = { craft: undefined } } = useQuery(GetCraftsWithUsersDocument, {
    variables: { organizationId },
  });
  const { data: { organization } = {} } = useQuery(GetOrganizationFlightBillingDocument, { variables: { id: organizationId } });
  const { data: { flightPurposes } = {} } = useQuery(GetFlightPurposesDocument, { variables: { where: { organizationId: { equals: organizationId } } } });
  const [currentCraft, setCurrentCraft] = useState(crafts?.find((craft) => craft.id === craftId) ?? crafts?.[0]);
  const [files, setFiles] = useState<File[]>([]);  
  const [route, setRoute] = useState<Route[]>( icaos ??  [{ icao:'' }, { icao:'' }]);
  const [passengers, setPassengers] = useState<Passenger[]>([]);
  const [initialValues, setInitialValues] = useState({
    flightDate: new Date(),
    purposeId: flightPurposes?.[0]?.id ?? '',
    customPurpose: '',
    craft: currentCraft?.id,
    pilot: '',
    intervals:  [...currentCraft?.intervals?? []].sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0).filter((interval) => interval.intervalType === IntervalType.Normal ).map(
      (interval, idx) => ({ name: interval?.name, intervalSubType: interval.intervalSubType, startValue: interval?.recentIntervalLog?.endValue, endValue: undefined, id: interval?.id })),
    consumables: currentCraft?.intervals?.filter((interval) => interval.intervalType === IntervalType.Consumable ).map((interval) => ({ name: interval?.name, startValue: interval?.recentIntervalLog?.endValue ?? undefined, endValue: undefined, id: interval?.id, subtype: interval.intervalSubType })),
    notes: '',
    fuelAdded: '',
    oilAdded: '',
    billable: false,
    proRataShare: false,
  });
  useEffect(() => {
    if(currentCraft && flightPurposes){
      setInitialValues(
        {
          ...initialValues,
          craft: currentCraft?.id,
          billable: flightPurposes?.[0].name !== "Maintenance" ? organization?.flightBillingEnabled : false,
          pilot: currentCraft?.userProfiles?.find( (tempProfile) => tempProfile?.id === user?.userOrganizationProfileId )?.id ?? currentCraft?.userProfiles?.[0]?.id,
          intervals:  [...currentCraft?.intervals ?? []].sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0)?.filter((interval) => { 
            if(interval.intervalType !== IntervalType.Normal) return false;
            if(currentCraft.loggingOption === LogType.Logtach && interval.intervalSubType === IntervalSubtype.Hobbs ) return false;
            if(currentCraft.loggingOption === LogType.Loghobbs && (interval.intervalSubType === IntervalSubtype.Tach || interval.intervalSubType === IntervalSubtype.Tachtwo) ) return false;
            return true;
          }).map(
            (interval) => ({ name: interval?.name, intervalSubType: interval?.intervalSubType, startValue: interval?.recentIntervalLog?.endValue, endValue: '', id: interval?.id })
          ),
          consumables: currentCraft?.intervals?.filter((interval) => interval.intervalType === IntervalType.Consumable ).map((interval) => ({ name: interval?.name, startValue: interval?.recentIntervalLog?.endValue, endValue: undefined, id: interval?.id, subtype: interval?.intervalSubType })).sort((a, b) => a.name.localeCompare(b.name)),
        }
      );
      if(route[0]?.icao === '' && route[1]?.icao === '' && currentCraft?.lastAirport){
        setRoute([{ icao: currentCraft?.lastAirport?.icao, id: currentCraft?.lastAirport?.id }, { icao: '' }]);
      }
    }
    if(flightPurposes){
      setInitialValues((preVal) => ({
        ...preVal,
        purposeId: flightPurposes?.[0]?.id ?? '',
      }));
    }
  }, [currentCraft, flightPurposes]); 
  let billingInterval = currentCraft?.loggingOption === LogType.Loghobbs ? currentCraft?.intervals.find( (interval) => interval.intervalSubType === IntervalSubtype.Hobbs ) : currentCraft?.intervals.find( (interval) => interval.intervalSubType === IntervalSubtype.Tach );
  if(currentCraft?.loggingOption === LogType.Logboth ) {
    billingInterval = currentCraft?.hourlyRateMethod === LogType.Loghobbs ? currentCraft?.intervals.find( (interval) => interval.intervalSubType === IntervalSubtype.Hobbs ) : currentCraft?.intervals.find( (interval) => interval.intervalSubType === IntervalSubtype.Tach );
  }
  function addAirport() {
    setRoute([...route, { icao:'' }]);
  }

  function removeAirport(index: number) {
    const newRoute = [...route];
    newRoute.splice(index, 1);
    setRoute(newRoute);
  }

  function updateAirport(index: number, type: string, value: string) {
    const newRoute = [...route];
    newRoute[index][type] = value;
    setRoute(newRoute);
  }

  function addPassengers() {
    setPassengers([...passengers, { name:'', id:'' }]);
  }

  function removePassenger(index: number) {
    const newPassengers = [...passengers];
    newPassengers.splice(index, 1);
    setPassengers(newPassengers);
  }

  function updatePassenger(index: number, type: string, value: string) {
    setPassengers((prev) => {
      const newPassengers = [...prev];
      newPassengers[index][type] = value;
      return newPassengers;
    });
  }
  
  useEffect(() => {
    if (crafts){
      setCurrentCraft(crafts.find((craft) => craft.id === craftId) ?? crafts[0]);
    }
  }, [crafts, craftId]);

  const handleSubmit = async (
    { ...values }: typeof initialValues,
    setSubmitting: (isSubmitting: boolean) => void ,
    resetForm: (nextState?: Partial<any> | undefined) => void
  ) => {
    const intervals = [ ...values.intervals.map((interval) => ( { ...interval, startValue: formatToFixedNumber(interval.startValue), endValue: formatToFixedNumber(interval.endValue), intervalType: IntervalType.Normal } ) ),
       ...values?.consumables.map((interval) => ( { ...interval, startValue: formatToFixedNumber(interval?.startValue), endValue: formatToFixedNumber(interval?.endValue), intervalType: IntervalType.Consumable } ) ) ];
    const purposeId = values.purposeId;
    const fuelAdded = Number(values.fuelAdded);
    const oilAdded = Number(values.oilAdded);
    const pilotId = values.pilot;
    delete values.intervals; delete values.consumables; delete values.pilot; delete values.purposeId; delete values.fuelAdded; delete values.oilAdded;
    const flightInput: FlightCreateWithIntervalsInput = {
      ...values,
      ...( route.filter(airport => airport.id).length > 0 && { route: { connect: route.filter((airport) => airport?.id).map((airport) => ({ id: airport.id })) } }),
      icaos: route.map((airport) => airport.icao),
      craft: { connect: { id: currentCraft.id }, },
      userProfile: { connect: { id: pilotId } },
      intervalsForFlight: intervals,
      proRataShare: passengers.length ? values.proRataShare : false,
      ...( passengers ? {passengers: { connect:  passengers.map((passenger) => ({ id: passenger?.id }))}} : { passengers : undefined }),
      consumableEntries: { create: [{ name: 'Fuel Added', value: fuelAdded, userOrgProfile: { connect: { id: pilotId } } },
                                    { name: 'Oil Added', value: oilAdded, userOrgProfile: { connect: { id: pilotId } } }] },
      ...(purposeId && purposeId !== 'Other' && { purpose:{ connect: { id: purposeId } } } ) ,
    };
    createFlight({ variables: { input: flightInput } }).then(
      async ( { data : { createFlightWithIntervals } = {} } ) => {
        if (files) {
          const formData = new FormData();
          files.forEach((file) => {
            formData.append('files', file);
          });
          formData.append('flightId', createFlightWithIntervals?.id);
          const res = await fetch(process.env.REACT_APP_API_URL + '/attachments/upload', { method: 'POST', body: formData });
        }
        setSubmitting(false);
        closeFlyout();
        resetForm();
      }
    ).catch(console.error);
  };
  return (
    <>
      <Toast {...toastProps} />
      <Formik enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={FlightSchema}
        validateOnMount={true}
        onSubmit={()=>{}}>
        {({ isSubmitting, values, setFieldValue, errors, setSubmitting, resetForm, isValid }) => {
          const totalBillableInterval = +Math.max((values?.intervals?.find( (inter) => inter?.id === billingInterval?.id )?.endValue ?? 0) - (values?.intervals?.find( (inter) => inter?.id === billingInterval?.id )?.startValue ?? 0), 0).toFixed(2);
          const totalBillable = +(values.proRataShare ? (currentCraft?.hourlyRate * totalBillableInterval) / (passengers.length + 1) : currentCraft?.hourlyRate * totalBillableInterval).toFixed(2);

          const fuelIndex = values?.consumables?.findIndex((item) => item.subtype === IntervalSubtype.Fuel);
          const oilIndex = values?.consumables?.findIndex((item) => item.subtype === IntervalSubtype.Oil); 
          return (
            <Form>
              <div className="flex flex-col p-4 h-full">
                <div className="border-t border-gray-200">
                  <Card title='General'>
                    <div className="flex w-full justify-between gap-3 mb-3">
                      <div className='flex flex-col w-full'>
                        <FullFormikSelect
                        name="craft"
                        label="Craft *"
                        onChange={(e) => {
                          setCurrentCraft(crafts?.find((c) => c.id === e.target.value));
                          setFieldValue('craft', e.target.value);
                        }}>
                          {crafts?.map((craftObj) => (
                            <option key={craftObj.id} value={craftObj.id}>
                              {craftObj.label}
                            </option>
                          ))}
                        </FullFormikSelect>
                      </div>
                      <div className='flex flex-col w-full'>
                        <FullFormikSelect
                        name="pilot"
                        label="Pilot *">
                          {currentCraft?.userProfiles?.filter((userProfile) => !passengers.find((value) => value.id === userProfile.id) ).map((userProfile) => (
                            <option key={userProfile.id} value={userProfile.id}>
                              {userProfile?.user?.lastName ? userProfile?.user?.firstName + ' ' + userProfile?.user?.lastName : userProfile?.nameInvite }
                            </option>
                          ))}
                        </FullFormikSelect>
                      </div>
                    </div>
                    <div className="flex w-full justify-between gap-3">
                      <div className='flex w-full'>
                        <FullFormikSelect name="purposeId" onChange={(event)=>{
                          setFieldValue('purposeId', event.target.value);
                          if(flightPurposes.find((purpose) => purpose.id === event.target.value)?.name === 'Maintenance' && values.billable){
                            setFieldValue('billable', false);
                          }else if(!values.billable){
                            setFieldValue('billable', true);
                          }
                        }} label="Purpose">
                          {flightPurposes?.map((purpose) => (
                            <option key={purpose.id} value={purpose.id}>
                              {purpose.name}
                            </option>
                          ))}
                          <option key="other" value="Other">
                            Other
                          </option>
                        </FullFormikSelect>
                      </div>
                      <div className='flex flex-col w-full'>
                        <FormLabel>Flight Date*</FormLabel>
                        <Field as={DatePicker} className="w-full" name="flightDate" />
                      </div>
                    </div>
                    { values.purposeId === 'Other' && <div className="flex w-full mt-3">
                      <FullFormikInput name="customPurpose" label="Custom Purpose" />
                    </div> }
                  </Card>
                  <Card title='Passengers'>
                    <div className="flex w-full justify-between gap-9 mb-9">
                      <div className='flex flex-col w-full'>
                        {passengers.map((profile, index) => (
                            <PassengerItem key={index} temp={ true } index={index} 
                            options={currentCraft?.userProfiles?.filter((userProfile) => 
                              !passengers.find((value) => value.id === userProfile.id) && values.pilot !== userProfile.id
                            ).map((userProfile) => ({ id: userProfile.id, name: userProfile?.user?.lastName ? userProfile?.user?.firstName + ' ' + userProfile?.user?.lastName : userProfile?.nameInvite }))} 
                            passenger={profile} handleItemUpdate={updatePassenger} handleRemoveItem={removePassenger} />
                        ))}
                      </div>
                    </div>
                    <div className="flex w-full justify-between items-center">
                      {passengers.length > 0 && <FullFormikCheckBox name="proRataShare" label='Enable Pro Rata Share' isVertical={true} />}
                      <Button onClick={() => addPassengers()} size='xs' color='navy' className='' text="Add Passenger" />
                    </div>
                  </Card>
                  <Card title='Route'>
                    <div className="flex w-full justify-between gap-9 mb-9">
                      <div className='flex flex-col w-full'>
                        {route.map((airport, index) => (
                            <RouteItem key={index} temp={ index > 1 } index={index} route={airport} handleItemUpdate={updateAirport} handleRemoveItem={removeAirport} />
                        ))}
                      </div>
                    </div>
                    <div className="flex w-full justify-end">
                      <Button onClick={() => addAirport()} size='xs' color='navy' className='' text="Add Airport" />
                    </div>
                  </Card>
                  <Card title='Intervals'>
                    <div className='flex mb-4 font-semibold'>
                      <span className='w-1/3'>Name:</span>
                      <span className='w-1/3'>Out:</span>
                      <span className='w-1/3'>In:</span>
                    </div>
                    { values?.intervals?.map((interval, index) => (
                      <IntervalItem required={true} key={index} interval={interval} error={errors?.intervals?.[index]} index={index} handleItemUpdate={(id, type, value) => {
                        //Find Idx of interval
                        const idx = values.intervals.findIndex((inter) => inter.id === id);
                        const newIntervals = [...values.intervals];
                        newIntervals[idx][type] = value;
                        setFieldValue('intervals', newIntervals);                      
                      }} />
                    )) }
                  </Card>
                  <Card title='Consumables'>
                    { organizationSubtype === OrgSubtypes.FREE ? 
                      <>
                        <span className='text-lg font-semibold text-slate-500'>Consumables are not available for free organizations</span>
                      </>
                    : 
                      <>{ values?.consumables && <>
                        <div className='flex mb-4 font-semibold'> 
                        <span className='w-1/3 ml-4'>&nbsp;</span>
                          <span className='w-1/3 ml-4'>Fuel Out:</span>
                          <span className='w-1/3 ml-4'>Fuel In:</span>
                        </div>
                        <IntervalItem interval={{...values?.consumables[fuelIndex], name: 'Fuel' }} error={errors?.consumables?.[fuelIndex]} index={fuelIndex} handleItemUpdate={(id, type, value) => {
                            const newIntervals = [...values?.consumables];
                            newIntervals[fuelIndex][type] = value;
                            setFieldValue('consumables', newIntervals);                      
                          }} />
                        <div className='flex mb-8'>
                          <span className='w-1/2 self-center'>Fuel Added</span>
                          <FullFormikInput name="fuelAdded" onChange={(e) => { 
                            setFieldValue('fuelAdded', e.target.value);
                            if(!values?.consumables[fuelIndex].startValue) return;
                            const newIntervals = [...values?.consumables];
                            newIntervals[fuelIndex].endValue = Number(e.target.value)+Number(values?.consumables[fuelIndex].startValue);
                            setFieldValue('consumables', newIntervals);   
                          }} className="w-1/3" />
                        </div>
                        <hr className='my-4 border-y-[2px] border-rounded border-slate-100'/>
                        <div className='flex mb-4 font-semibold'> 
                          <span className='w-1/3 ml-4'>&nbsp;</span>
                          <span className='w-1/3 ml-4'>Oil Out:</span>
                          <span className='w-1/3 ml-4'>Oil In:</span>
                        </div>
                        <IntervalItem interval={{...values?.consumables[oilIndex], name: 'Oil' }} error={errors?.consumables?.[oilIndex]} index={oilIndex} handleItemUpdate={(id, type, value) => {                          
                            const newIntervals = [...values?.consumables];
                            newIntervals[oilIndex][type] = value;
                            setFieldValue('consumables', newIntervals);                      
                          }} />
                        <div className='flex mb-4'>
                          <span className='w-1/2 self-center'>Oil Added</span>
                          <FullFormikInput name="oilAdded" className="w-1/3" />
                        </div>
                      </> } </>
                    }
                    
                  </Card>
                  <Card title='Flight Attachments'>
                      <FilePicker files={files} setFiles={setFiles} />
                  </Card>
                  <div className="bg-white px-4 py-5 my-4 flex items-center justify-center shadow border border-slate-200">
                    <dt className="text-sm font-medium text-gray-500 w-1/3">Notes</dt>
                    <Field as="textarea" name="notes" className="w-2/3 border-gray-300 rounded" />
                  </div>
                  { organization?.flightBillingEnabled && <div className="bg-white px-4 py-5 my-4 flex flex-col items-center shadow border border-slate-200 item-center">
                    <div className='flex w-full justify-between' onClick={() => setFieldValue('billable', !values.billable)}>
                      <dt className="text-sm font-medium text-gray-500 w-1/3">Flight Billable</dt>
                      <FullFormikCheckBox name="billable" />
                    </div>
                    {values.billable && <div className='flex justify-end w-full my-4'>
                      <div className='w-full flex flex-col bg-brand-pale p-5 gap-10'>
                        <div className='justify-between flex w-full'>
                          <dt className="font-medium text-gray-500">Estimated Flight Charges</dt>
                          <span className="text-brand-dark">$ {totalBillable}</span>
                        </div>
                        <div className='justify-end flex w-full'>
                          <dt className="font-medium text-gray-500">{totalBillableInterval} {webFormat(billingInterval?.intervalSubType)} * {formatUSD(currentCraft?.hourlyRate)}/hr hourly rate</dt>
                        </div>
                        {values.proRataShare && <>
                          <div className='justify-between flex w-full'>
                            <dt className="font-medium text-gray-500">Pro Rata Share</dt>
                            <span className="text-brand-dark">$ {totalBillable / (passengers.length + 1)}</span>
                          </div>
                          <div className='justify-end flex w-full'>
                            <dt className="font-medium text-gray-500">{totalBillableInterval}/hr hourly rate per person</dt>
                          </div>
                        </>
                        }
                        <div className='justify-end flex w-full'>
                          <dt className="font-medium text-gray-500">This flight will generate a transaction based on the Hourly Rate of {formatUSD(currentCraft?.hourlyRate)} multiplied by {totalBillableInterval} {webFormat(billingInterval?.intervalSubType)} hours flown {values.proRataShare ? `divided by ${(passengers.length + 1)} people` : ''}. <br></br>(Note editing a flight will not update the transaction)</dt>
                        </div>
                      </div>
                    </div>}
                  </div> }
                  <hr />
                  <div className='flex justify-end'>
                    <div className="flex flex-col items-end justify-end mt-2">
                      <Button disabled={isSubmitting || !isValid} onClick={(e) => {
                        handleSubmit(values, setSubmitting, resetForm);
                      } } 
                        text={"Record Flight"} color="navy" type="submit" />
                      { !isValid && <span className='text-sm text-red-500 mt-1'>{'Unable to Record, Invaild Data'}</span> }
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  );
};

export default New;
